import { ComponentProps } from "react";
import Link from "next/link";
import { CurationContentCard } from "./CurationContentCard";
import { OriginalContentCard } from "./OriginalContentCard";
import { OriginalUpcomingCard } from "./OriginalUpcomingCard";
import { ContentCardScaffold } from "./scaffolds/ContentCardScaffold";
import { css } from "@emotion/react";

type ContentCardProps = ComponentProps<typeof ContentCardScaffold> & {
  /**
   * 콘텐츠 카드의 타입을 지정합니다.
   */
  type: "curation" | "original" | "upcoming";
  /**
   * 해상도를 수동으로 지정합니다. device가 없을 경우, media query를 사용하여 자동으로 변경됩니다.
   * ex. 오리지널 카드는 index 페이지에서 web+tablet으로 고정합니다.
   */
  device?: "web+tablet" | "app";
  interaction?: "hover";
  content: Mix_ContentFragment;
};

export const ContentCard = ({ type, ...props }: ContentCardProps) => {
  const Component = {
    curation: CurationContentCard,
    original: OriginalContentCard,
    upcoming: OriginalUpcomingCard,
  }[type];

  return (
    <Link
      css={css`
        pointer-events: ${type === "upcoming" ? "none" : "auto"};
      `}
      href={
        process.env.BUILD_TARGET === "extension"
          ? `https://mix.day/contents/${props.content.id}`
          : `/contents/${props.content.id}`
      }
      target={props.content.isoriginal ? "_self" : "_blank"}
      rel="noreferrer"
    >
      <Component {...props} />
    </Link>
  );
};
