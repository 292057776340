import dayjs from "dayjs";
import { Mix_Content_Category_Enum } from "graphql/generated";

export module AppCategory {
  export const categories: {
    [key in Mix_Content_Category_Enum]: {
      public: boolean;
    };
  } = {
    [Mix_Content_Category_Enum.Ambassador]: { public: false },
    [Mix_Content_Category_Enum.Cmo]: { public: false },
    [Mix_Content_Category_Enum.Offline]: { public: true },
    [Mix_Content_Category_Enum.Report]: { public: true },
    [Mix_Content_Category_Enum.BestContents]: { public: true },
    [Mix_Content_Category_Enum.Event]: { public: true },
    [Mix_Content_Category_Enum.GlobalInsight]: { public: true },
  };

  export const toContentInfo = (
    content: Pick<
      Mix_ContentFragment,
      | "title"
      | "category"
      | "authorname"
      | "offlinetime"
      | "updatetime"
      | "readtime"
      | "pdfpagecount"
    >,
  ) => {
    switch (content.category) {
      case Mix_Content_Category_Enum.Offline:
        if (content.title === "POST FORUM 2024") {
          return [
            {
              title: "주최",
              content: "더에스엠씨그룹 The SMC Group",
            },
            {
              title: "일정",
              content: dayjs(content.offlinetime).format("YYYY.MM.DD HH:mm"),
            },
            {
              title: "위치",
              content: "서울 강남구 언주로 564 라움아트센터 2F",
            },
          ];
        }

        return [
          {
            title: "연사",
            content: content.authorname,
          },
          {
            title: "일정",
            content: dayjs(content.offlinetime).format("YYYY.MM.DD HH:mm"),
          },
          {
            title: "주최",
            content: "MIX",
          },
        ];
      case Mix_Content_Category_Enum.Report:
        return [
          {
            title: "작가",
            content: content.authorname,
          },
          {
            title: "게재일",
            content: dayjs(content.updatetime).format("YYYY.MM.DD"),
          },
          {
            title: "페이지 수",
            content: content.pdfpagecount + "장",
          },
        ];
      default:
        return [
          {
            title: "작가",
            content: content.authorname,
          },
          {
            title: "게재일",
            content: dayjs(content.updatetime).format("YYYY.MM.DD"),
          },
          {
            title: "예상 소요시간",
            content: content.readtime + "분",
          },
        ];
    }
  };
}
