import { ComponentProps } from "react";
import { ContentCard } from "../ContentCard";
import { ContentCardScaffold } from "./ContentCardScaffold";
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Mix_Content_Category_Enum } from "graphql/generated";
import { AppCategory } from "models/AppCategory";

const ThumnailBox = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1.067; // w 320, h 300
  border-radius: 12px;
  margin-bottom: 12px;
`;

const ThumnailOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
`;

export const VerticalFullCard = ({
  content,
  interaction,
  ...props
}: Omit<ComponentProps<typeof ContentCard>, "type">) => {
  const theme = useTheme();

  return (
    <ContentCardScaffold
      css={css`
        --padding: 16px;

        .bookmark {
          display: none;
        }

        .thumbnail-overlay {
          background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0.1) 43.23%,
              #000 100%
            ),
            50% / cover no-repeat;
        }

        :hover,
        &[data-interaction="hover"],
        &[data-bookmarked="true"] {
          .bookmark {
            display: flex;
          }

          .thumbnail-overlay {
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2) 0%,
                rgba(0, 0, 0, 0.2) 100%
              ),
              linear-gradient(180deg, rgba(0, 0, 0, 0.1) 43.23%, #000 100%),
              50% / cover no-repeat;
          }
        }

        @media (max-width: 768px) {
          .bookmark {
            display: flex;
          }
        }
      `}
      data-interaction={interaction}
      data-bookmarked={!!content.bookmarked}
      {...props}
    >
      <ThumnailBox>
        <ContentCardScaffold.ThumbnailImg
          css={css`
            border-radius: inherit;
          `}
          src={content.imgurl ?? ""}
          loader={({ src }) => src + "?w=768"}
          fill={true}
        />
        <ThumnailOverlay className="thumbnail-overlay" />
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: var(--padding);
            z-index: 1;
          `}
        >
          {content.category && (
            <ContentCardScaffold.CategoryChip category={content.category} />
          )}
          <ContentCardScaffold.Bookmark
            className="bookmark"
            variant="filledDark"
            content={content}
          />
        </div>
        <div
          css={css`
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            padding: var(--padding);
            z-index: 1;
          `}
        >
          <ContentCardScaffold.Title
            css={css`
              max-width: 80%;
              margin-bottom: 4px;
            `}
            color="common.white.main"
          >
            {content.title}
          </ContentCardScaffold.Title>
          {content.title === "POST FORUM 2024" ? (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                margin-bottom: 12px;

                span {
                  color: ${theme.palette.grey[200]};
                }
              `}
            >
              <div>
                <ContentCardScaffold.AuthorName>
                  {
                    AppCategory.toContentInfo(content).find(
                      (item) => item.title === "주최",
                    )?.content
                  }
                </ContentCardScaffold.AuthorName>
                <span> · </span>
              </div>
              <ContentCardScaffold.UpdateTime
                updatetime={content.updatetime}
                offlinetime={content.offlinetime}
                isOffline={
                  content.category === Mix_Content_Category_Enum.Offline
                }
              />
            </div>
          ) : (
            <div
              css={css`
                margin-bottom: 12px;
                word-break: keep-all;

                span {
                  color: ${theme.palette.grey[200]};
                }
              `}
            >
              <ContentCardScaffold.AuthorName>
                {content.authorname}
              </ContentCardScaffold.AuthorName>
              <span> · </span>
              <ContentCardScaffold.UpdateTime
                updatetime={content.updatetime}
                offlinetime={content.offlinetime}
                isOffline={
                  content.category === Mix_Content_Category_Enum.Offline
                }
              />
            </div>
          )}
          <ContentCardScaffold.Tags
            tags={content.mix_content_tags}
            color="primary.main"
          />
        </div>
      </ThumnailBox>
    </ContentCardScaffold>
  );
};
